
  import { defineComponent, ref, onMounted } from "vue";
  import Datatable from "@/components/kt-datatable/KTDatatable.vue";
  // import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  import { IPagedResultDtoOfGetProductBundleForViewDto } from "@/shared/service-proxies/generated-proxies";
  import useProductBundle from "@/composables/useProductBundle";
  import { ProductBundleFilter } from "@/models";
  import AJLoader from "@/components/AjaxLoader.vue";
  // import useProducts from "@/composables/useProducts";
  import { useStore } from "vuex";
  import { formatPrice } from "@/core/services/HelperServices";

  export default defineComponent({
    name: "ProductBundle",
    components: {
      Datatable,
      AJLoader,
    },
    setup() {
      const { productBundles, getProductBundles } = useProductBundle();

      let loading = ref<boolean>(true);
      const showAdvanceFilter = ref<boolean>(false);

      const defaultFilter = ref({
        sorting: null,
        skipCount: 0,
        maxResultCount: 1000,
      } as unknown as ProductBundleFilter);

      const tableHeader = ref([
        {
          name: "Actions",
          key: "actions",
        },
        {
          name: "Name",
          key: "name",
          sortable: true,
        },
        {
          name: "Display Name",
          key: "displayName",
          sortable: true,
        },
        {
          name: "Unit Price",
          key: "unitPrice",
          sortable: true,
        },
        {
          name: "Area",
          key: "area",
          sortable: true,
        },
        {
          name: "IsActive",
          key: "isActive",
          sortable: true,
        },
      ]);

      const tableData = ref<Array<IPagedResultDtoOfGetProductBundleForViewDto>>(
        []
      );

      const initialData = ref<
        Array<IPagedResultDtoOfGetProductBundleForViewDto>
      >([]);
      const breadcrumbs = {
        title: "Product Bundle",
        breadcrumbs: ["Product Bundles"],
      };

      const store = useStore();
      const config = store.getters.getConfigurations;
      const permissions = config.result.auth.grantedPermissions;
      onMounted(async () => {
        // setCurrentPageBreadcrumbs("Products", ["Products header info"]);

        await getData();
      });

      const getData = async () => {
        loading.value = true;

        await getProductBundles(
          defaultFilter.value as unknown as ProductBundleFilter
        );

        tableData.value = productBundles.value;

        initialData.value.splice(0, tableData.value.length, ...tableData.value);

        loading.value = false;
      };

      const searchPriceList = async () => {
        await getData();
      };

      return {
        defaultFilter,
        loading,
        tableData,
        tableHeader,
        showAdvanceFilter,
        searchPriceList,
        breadcrumbs,
        permissions,
        formatPrice,
      };
    },
  });
