import moment from "moment";

export const formatCurrency = (currency: number) => {
  return new Intl.NumberFormat("en-IN").format(currency);
};

export const formatDateTime = (date: Date) => {
  return moment(date).format("DD-MMMM-YYYY, h:mm");
};

export const formatPrice = (value: any): string => {
  return new Intl.NumberFormat().format(value);
};
