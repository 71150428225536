import { ref, Ref } from "vue";
import { axiosClient, APP_BASE_URL } from "@/core/services/AxiosClient";
import { ProductBundleFilter } from "@/models";
import { CancelToken } from "axios";
import {
  ProductBundleServiceProxy,
  IPagedResultDtoOfGetProductBundleForViewDto,
  PagedResultDtoOfGetProductBundleForViewDto,
  GetProductBundleForViewDto,
  CreateOrEditProductBundleDto,
  IGetProductBundleForViewDto,
} from "@/shared/service-proxies/generated-proxies";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface UsableProductBundle {
  productBundles: Ref<Array<IPagedResultDtoOfGetProductBundleForViewDto>>;
  productBundle: Ref<IGetProductBundleForViewDto>;
  getProductBundles: (filters: ProductBundleFilter) => void;
  getProductBundle: (id: string) => void;
  updateOrCreateProductBundle: (
    product: CreateOrEditProductBundleDto,
    cancelToken: undefined | CancelToken
  ) => void;
  uploadImage: (formData: any) => Promise<boolean>;
}

const useProductBundle = (): UsableProductBundle => {
  const productBundles: Ref<
    Array<IPagedResultDtoOfGetProductBundleForViewDto>
  > = ref([] as unknown as Array<IPagedResultDtoOfGetProductBundleForViewDto>);

  const client = new ProductBundleServiceProxy(APP_BASE_URL, axiosClient);

  const productBundle: Ref<IGetProductBundleForViewDto> = ref(
    null as unknown as IGetProductBundleForViewDto
  );

  const store = useStore();

  const getProductBundles = async (filters: ProductBundleFilter) => {
    const [sorting, skipCount, maxResultCount, cancelToken] = [
      ...Object.values(filters),
    ];
    await client
      .getAll(sorting, skipCount, maxResultCount, cancelToken)
      .then((data) => {
        productBundles.value =
          data.items as unknown as Array<PagedResultDtoOfGetProductBundleForViewDto>;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };
  const updateOrCreateProductBundle = async (
    inputData: CreateOrEditProductBundleDto,
    cancelToken: CancelToken | undefined
  ) => {
    await client
      .createOrEdit(inputData, cancelToken)
      .then((data) => {
        // productBundle.value = data as unknown as IPagedResultDtoOfGetProductBundleForViewDto;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
      });
  };

  const getProductBundle = async (id: string) => {
    await client
      .getProductBundleForView(id)
      .then((data) => {
        productBundle.value = data;
      })
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error);
      });
  };
  const uploadImage = async (data: any) => {
    const rt = await ApiService.post("/ProductBundle/UploadImage", data)
      .then(() => true)
      .catch((error) => {
        store.commit(Mutations.SET_ERROR, error.response.error);
        return false;
      });
    console.log(rt);
    return rt;
  };

  return {
    uploadImage,
    productBundles,
    getProductBundles,
    getProductBundle,
    updateOrCreateProductBundle,
    productBundle,
  };
};

export default useProductBundle;
